import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import ProductCard from "../../components/productCard/ProductCard";
import Accordion from "../../components/accordion/Accordion";
import ContactUs from "../../components/contactUs/ContactUs";
import { getData } from "../../utils";
import "./products.scss";
import filter from "../../assets/svg/filter.svg";
import SEO from "../../components/SEO/SEO";
import GenreCard from "../../components/genreCard/genreCard";

function Products() {
  const { id } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const page = queryParams.get("page") || 1;
  const [filters, setFilters] = useState({});
  const [sidebar, setSidebar] = useState({});
  const [products, setProducts] = useState([]);
  const [totalPage, setTotalPage] = useState(null);
  const [openFilterBtn, setOpenFilterBtn] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [genres, setGenres] = useState([]);
  const [genresShow, setGenresShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(Number(page));

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("scrollPosition");
    const savedProductId = sessionStorage.getItem("selectedProductId");
    if (savedScrollPosition && savedProductId) {
      const productElement = document.getElementById(savedProductId);
      if (productElement) {
        productElement.scrollIntoView({ behavior: "smooth" });
        sessionStorage.removeItem("scrollPosition");
        sessionStorage.removeItem("selectedProductId");
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      sessionStorage.setItem("scrollPosition", window.scrollY);
    };
  }, []);

  useEffect(() => {
    async function getFilters(url) {
      const data = await getData(url);
      setFilters(data);
    }
    getFilters(`category/${id}/`);
  }, [id]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [id]);

  useEffect(() => {
    async function getSidebar(url) {
      const data = await getData(url);
      setSidebar(data);
      const genres = data?.data?.filter((item) => item?.name === "Жанры");
      if (
        (filters?.name === "ГРАВЮРЫ" && !search) ||
        (filters?.name === "ФАРФОР" && !search) ||
        (filters?.name === "ЖИВОПИСЬ ГРАФИКА РИСУНОК" && !search)
      ) {
        setGenres(genres[0]?.subcategories);
        setGenresShow(true);
      } else {
        setGenresShow(false);
      }
    }
    getSidebar(`category/${id}/sidebar/`);
  }, [id, filters, search]);

  useEffect(() => {
    const getDataProducts = async () => {
      const data = await getData(
        `products/?category_id=${id}&${search && "&" + search.replace("?", "")}`
      );
      setProducts(data?.results);
      setTotalPage(Math.ceil(data?.count / 24));
    };
    getDataProducts();
  }, [id, page]);

  async function getSidebarLinks(catId) {
    const data = await getData(`category/${catId !== 0 ? catId : id}/sidebar`);
    setSidebar(data);
  }

  function filterSubCategory(n, catId) {
    const categories = document.querySelectorAll(".filter");
    categories.forEach((el, index) => {
      el.classList.remove("filter__active");
      if (n === index) el.classList.add("filter__active");
    });
    setCategoryId(catId);
    getSidebarLinks(catId);
  }

  const topFilters = [
    ...[{ id: 0, name: "Все работы" }],
    ...(filters?.subcategories || []),
  ];

  const loadMoreProducts = async (newPage) => {
    setCurrentPage(newPage);
    const data = await getData(
      `products/?category_id=${id}&page=${newPage}${
        search && "&" + search.replace("?", "")
      }`
    );
    setProducts((prevData) => [...prevData, ...data?.results]);
  };

  function openMobileFilters() {
    setOpenFilterBtn(!openFilterBtn);
  }

  const handleProductClick = (productId) => {
    sessionStorage.setItem("selectedProductId", productId);
  };

  return (
    <div className="products">
      <SEO title={filters?.name} description={filters?.description} />
      <section className="section">
        <div className="container">
          <h2 className="subtitle">{filters?.name}</h2>
          <div className="filters-wrapper">
            <div className="filters">
              {topFilters.map((el, index) => (
                <span
                  onClick={() => filterSubCategory(index, el.id)}
                  key={el.id}
                  className={`filter ${index === 0 && "filter__active"}`}
                >
                  {el.name}
                </span>
              ))}
            </div>
            <div className="filters__btn">
              <div onClick={openMobileFilters} className="filters__btn_head">
                <span>Все фильтры</span>
                <span>
                  <img src={filter} alt="filter" />
                </span>
              </div>
              <div
                className={`filter__btn_accordion ${
                  openFilterBtn && "filter__btn_accordion--open"
                }`}
              >
                {sidebar?.data?.map((accordion) => (
                  <Accordion
                    key={accordion.id}
                    acc={accordion}
                    setProducts={setProducts}
                    categoryId={categoryId}
                    catalogId={id}
                    setOpenFilterBtn={setOpenFilterBtn}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="products__layout">
            <div className="filters__large">
              {sidebar?.data?.map((accordion) => (
                <Accordion
                  key={accordion.id}
                  acc={accordion}
                  setProducts={setProducts}
                  categoryId={categoryId}
                  catalogId={id}
                />
              ))}
            </div>
            <div className="products__grid">
              {!genresShow
                ? products?.map((product) => (
                    <Link
                      key={product.id}
                      to={`/catalog/${id}/${product.id}`}
                      onClick={() => handleProductClick(product.id)}
                    >
                      <ProductCard
                        key={product.id}
                        el={product}
                        id={`product-${product.id}`}
                      />
                    </Link>
                  ))
                : genres?.map((product) => (
                    <Link
                      key={product.id}
                      to={`?sidebar_id=${product.id}`}
                      onClick={() => setGenresShow(false)}
                    >
                      <GenreCard key={product.id} el={product} />
                    </Link>
                  ))}
            </div>
          </div>
          <div className="pagination">
            <button
              className="load-more"
              disabled={currentPage >= totalPage}
              onClick={() => loadMoreProducts(currentPage + 1)}
            >
              Загрузить больше
            </button>
          </div>
        </div>
      </section>
      <ContactUs />
    </div>
  );
}

export default Products;
